.add__course__main_container {
    padding: 80px 281px 150px 31px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .general__course__text {
    font-family: Montserrat-Bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    text-align: left;
    color: #ffffff;
  }
  
  .add__course__container {
    display: flex;
    flex-direction: column;
    border: 1px solid #24262b;
    padding: 32px;
    gap: 40px;
    margin-bottom: 60px !important;
    border-radius: 16px;
  }
  
  .ql-container.ql-snow {
    border: none !important;
  }
  
  .ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #444 !important;
  }
  
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: transparent !important;
    background: #444 !important;
    border-radius: 12px !important;
  }
  
  .ql-snow .ql-picker-options .ql-picker-item {
    color: #9ea0a5 !important;
  }
  
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    top: 10% !important;
  }
  
  .ql-editor {
    padding: 12px 0px !important;
  }
  
  .ql-container,
  .ql-snow .ql-picker-label::before,
  .ql-editor::before {
    color: #9ea0a5 !important;
    font-family: Montserrat !important;
    font-style: normal !important;
  }
  
  .MuiDropzonePreviewList-image {
    display: none !important;
  }
  
  .editimageselected {
    /* margin-top: -154.5px !important; */
    z-index: 0;
  
  }
  
  .editvideopreview_llll {
  
    max-height: 350px;
    /* margin-top: -154.5px !important; */
    border-radius: 16px;
    z-index: 0;
  
  }
  
  .sub__section__info_container {
    display: flex;
    flex-direction: column;
    border: 1px solid #24262b;
    padding: 32px;
    gap: 40px;
    border-radius: 16px;
  }
  
  .add__course__input_container {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
  
  .add__course__inputlabel {
    font-family: Montserrat-Bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: left;
    color: #ffffff;
  }
  
  .add__course__input_placeholder {
    padding: 20px 18px;
    background: #24262b;
    border-radius: 16px;
    border: none;
    color: #9ea0a5
  }
  
  .add__course__input_placeholder::placeholder {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: #9ea0a5;
    width: 100%;
  }
  
  .add__course__input_placeholder:focus {
    outline: none;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #9ea0a5;
  }
  
  .add__course__input_placeholder_description {
    padding: 20px 18px 0px;
    background: #24262b;
    border-radius: 16px;
    border: none;
    color: #9ea0a5;
    height: 200px;
  }
  
  .add__course__input_placeholder_description::placeholder {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: #9ea0a5;
    width: 100%;
  }
  
  .add__course__input_placeholder_description:focus {
    outline: none;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #9ea0a5 !important;
  }
  
  .dropdown__main__container_inner {
    padding: 19px 20px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    background: #24262b;
    width: 100%;
    border: none;
  }
  
  .dropdown__list__container {
    background: #24262b !important;
  
    padding: 20px !important;
    border-radius: 20px !important;
    width: 100%;
  }
  
  .dropdown__main__container_inner::after {
    display: none !important;
  }
  
  .dropdown__text__1 {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: #9ea0a5;
  }
  
  .dropdown__item__text {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: #ffffff;
    border-bottom: 1px solid #50535b;
    padding-bottom: 15px;
  }
  .dropdown__item__text:hover {
    opacity: 0.7;
  background: transparent !important;
  color: #ffffff !important;
  }
  .MuiDropzonePreviewList-removeButton {
  display: none !important; 
  }
  .ql-editor.ql-blank::before {
    left: unset !important;
    right: unset !important;
  }
  .Toastify__toast-body {
  gap: 15px !important;
  }
  .dropdown__item__text_1 {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: #ffffff;
  }
  .dropdown__item__text_1:hover
  {
    opacity: 0.7;
    color: #ffffff !important;
    background: transparent !important;
  }
  .dropdown__main__container_inner:hover {
    background: #24262b !important;
  }
  
  .dropdown__main__container_inner:focus {
    background: #24262b !important;
  }
  
  .dropdown__list__container_1 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .image__dropzone__main {
    background: #24262b !important;
    border-radius: 16px !important;
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    position: relative;
    /* width: 300px; */
  }
  
  .MuiDropzoneArea-root {
    min-height: 155px !important;
    border: none !important;
    background: #24262b !important;
    border-radius: 16px !important;
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    position: unset !important;
  }
  
  .upload-image-zone {
    width: 100%;
    padding: 16px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    text-align: center;
    background-color: #f7f7f7;
  }
  
  .upload-image-zone .uploaded-image {
    width: 100%;
    height: 155px;
    border-radius: 16px;
    object-fit: cover;
    margin-top: 16px;
    padding-left: 0px !important;
  }
  
  .MuiDropzonePreviewList-image {
    height: 155px !important;
    max-height: none !important;
    max-width: none !important;
    width: 800px !important;
    overflow: hidden !important;
  }
  
  .MuiDropzonePreviewList-image {
    transform: translate(-267px, -30px)
  }
  
  .MuiDropzoneArea-textContainer {
    display: flex !important;
    flex-direction: column-reverse !important;
    gap: 5px !important;
    align-items: center !important;
    margin-top: 35px !important;
  }
  
  .MuiDropzoneArea-icon {
    color: #9ea0a5 !important;
    height: 61px !important;
    width: 61px !important;
  }
  
  .MuiDropzoneArea-text {
    font-family: Montserrat-Medium !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19.5px !important;
    color: #9ea0a5 !important;
  }
  
  .course__section__info_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 62px !important;
  }
  
  .course__section_info_text {
    font-family: Montserrat-Bold;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    color: #ffffff;
  }
  
  .course__section__info_container_form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid #24262b;
  }
  
  .add_subsection_button {
    height: 45px;
    width: 182px;
    background: #0f6fff;
    border-radius: 16px;
    border: none;
  }
  
  .add_subsection_button_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
  }
  
  .add_subsection_text {
    font-family: Montserrat-Bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    color: #ffffff;
    white-space: nowrap
  }
  
  .delete_subsection_button {
    height: 45px;
    width: 200px;
    border-radius: 16px;
    background: #f04a4a;
    border: none;
  }
  
  .delete_subsection_button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  
  .add__section__button {
    height: 45px !important;
    width: 150px !important;
    border-radius: 16px !important;
    border: none;
    background: #0f6fff !important;
  }
  
  .publish__button__main__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 32px !important;
  }
  
  .publish___button_main {
    height: 60px;
    width: 190px;
    border-radius: 16px;
    background: #0f6fff;
    border: none;
    font-family: Montserrat-Semibold;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
  
  .cancel___button_main {
    height: 60px;
    width: 190px;
    border-radius: 16px;
    background: #393c43;
    backdrop-filter: blur(16px);
    border: none;
    font-family: Montserrat-Semibold;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
  
  .uploaded-video {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .MuiGrid-spacing-xs-8 {
    position: absolute !important;
    display: flex !important;
    justify-content: center !important;
  }
  
  @media (max-width: 1300px) {
    .add__course__main_container {
      padding: 80px 100px 150px 31px;
    }
  }
  
  @media (max-width: 800px) {
    .add__course__main_container {
      padding: 80px 30px 150px 31px;
    }
  }
  
  @media (max-width: 600px) {
    .add__course__main_container {
      padding: 80px 0px 0px 0px;
    }
  }
  
  @media(max-width:435px) {
    .publish__button__main__container {
      justify-content: center;
    }
  }
  
  @media(max-width:425px) {
    .MuiDropzoneArea-root {
      min-height: 130px !important;
    }
  
    .editvideopreview_llll {
      /* margin-top: -130.5px !important; */
    }
    .editimageselected {
      /* margin-top: -126.5px !important; */
      z-index: 0;
  }
  }
  
  @media(max-width:375px) {
    .MuiDropzoneArea-textContainer {
      margin-top: 0px !important;
    }
    .editimageselected {
      /* margin-top: -103.5px !important; */
      z-index: 0;
  }
    .MuiDropzoneArea-root {
      min-height: 104px !important;
  
    }
  
    .editvideopreview_llll {
      /* margin-top: -103.5px !important; */
    }
  
  }