.ContentLibraryMainDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #111214;
  padding: 70px 26px 0px 7px;
}

.ContentLibraryTop {
  flex-wrap: wrap;
}

.Content {
  /* max-width: 1176px; */
  width: 100%;
  /* padding: 40px 55px 40px 32px; */
  background: #111214;
  min-height: 100vh;
}

.ContentLibraryTop {
  max-width: 1, 089px;
  /* height: 200px; */
  gap: 22px;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.ddddrop {
  align-self: flex-start;
}
.ddddropdd {
  align-self: center;
}
.ContentLibraryTop_Div1 {
  width: 370px;
  height: 60px;
  /* padding: 27px 35px 27px 36px; */
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background: #24262b;
  cursor: pointer;
  padding: 15px 24px;
}
.contentdiveeeee {
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
  align-items: center;
}
.DropDownmenuOptionDivvvvv:hover {
  background: transparent !important;
}
.DropDownmenuOptionDivvvvv:focus {
  background: transparent !important;
}

.ContentLibraryTop_Div1:focus-within {
  border: 1px solid #0f6fff;
  box-shadow: 0px 0px 0px 4px #0f6fff40;
  background: #393c43;
}

.ContentLibraryTop_Div1:hover {
  border: 1px solid #0f6fff !important;
  box-shadow: 0px 0px 0px 4px #0f6fff40 !important;
  background: #393c43 !important;
}


.ContentLibraryTop_Div2 {
  display: flex;
  flex-direction: column;
}

.ContentLibraryTop_Div2_p1 {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 24.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0px !important;
}

.ContentLibraryTop_Div2_p2 {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9ea0a5;
  margin: 0px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

@media (max-width: 1360px) {
  .ContentLibraryTop {
    flex-wrap: wrap;
  }
}

@media (max-width: 515px) {
  .ContentLibraryTop_Div1 {
    width: 100%;
  }

  .Content {
    padding: 30px 0px 40px 0px;
  }




}

@media (max-width: 400px) {


  .ContentLibraryTop_Div2_p1 {
    font-size: 14px;
  }

  .ContentLibraryTop {
    gap: 10px;
  }

  .ContentLibraryTop_Div1 {
    gap: 15px;
  }

  .ContentLibraryMainDiv {
    padding: 70px 0px 0px 0px;
  }
}