.Add_folderP{
    font-family: Montserrat-Bold;
font-size: 16px;
font-weight: 700;
line-height: 19.5px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin:  0px !important;
}
.inputEnterfolder{
    background: #393C43;
width: 400px;
height: 60px;
padding: 20px 20px 20px 20px;
border-radius: 16px;
border: none;
font-family: Montserrat-Medium !important;
font-size: 16px !important;
font-weight: 500 !important;
line-height: 19.5px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #9EA0A5 !important;

}
.inputEnterfolder:focus{
    outline: none;
}

.inputEnterfolder::placeholder{
    font-family: Montserrat-Medium !important;
font-size: 16px !important;
font-weight: 500 !important;
line-height: 19.5px !important;
text-align: left !important;
text-underline-position: from-font !important;
text-decoration-skip-ink: none !important;
color: #9EA0A5 !important;

}

.addmodell{
    /* width: 535px; */
    border-radius: 16px;
    backdrop-filter: blur(114px);
    background: #24262B;
}
.Folder__Namediv{
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.add__modal__button_container{
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 40px !important;

}
.add__modal__button_container_cancel{
    width: 190px;
    height: 60px;
    /* padding: 16px 124px 16px 124px; */
    gap: 10px;
    border-radius: 16px;
    background: #393C43;
    backdrop-filter: blur(16px);
    font-family: Montserrat-SemiBold;
font-size: 18px;
font-weight: 600;
line-height: 18px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px !important;
border: none;
    
}
.delete__modal_yes_button_add{
    width: 190px;
    height: 60px;
    /* padding: 16px 124px 16px 124px; */
    gap: 10px;
    border-radius: 16px ;
    background: #0F6FFF;
    backdrop-filter: blur(16px);
    border: none;
    font-family: Montserrat-SemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
}

.ffolderdiv{
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 100%;
}

.add__task__modal__container_1 {
    display: flex
;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




/* ...................... */


@media (max-width: 768px) {

    .delete__task__modal_container {

padding: 10px;
    }
    .logo__modal__im{
        height: 65px;
        width: 65px;
    }
    .addmodell {
      /* width: 100%; */
      width: 381px;
      border-radius: 12px;
      padding: 20px;
    }
  
    .inputEnterfolder {
      width: 100%;
      height: 50px;
      padding: 15px;
      font-size: 14px !important;
      line-height: 18px !important;
    }
  
    .Add_folderP {
      font-size: 14px;
      line-height: 18px;
    }
  
    .add__modal__button_container {

      gap: 10px;
    }
  
    .add__modal__button_container_cancel,
    .delete__modal_yes_button_add {
      width: 100%;
      height: 50px;
      font-size: 16px;
    }
  
    .ffolderdiv {
      gap: 10px;
    }
  
    .add__task__modal__container_1 {
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .addmodell {
      /* width: 95%; */
      width: 338px;
      border-radius: 10px;
      padding: 15px;
    }
  
    .inputEnterfolder {
      height: 45px;
      padding: 10px;
      font-size: 12px !important;
      line-height: 16px !important;
    }
  
    .Add_folderP {
      font-size: 12px;
      line-height: 16px;
    }
  
    .add__modal__button_container {
      gap: 8px;
    }
  
    .add__modal__button_container_cancel,
    .delete__modal_yes_button_add {
      height: 45px;
      font-size: 14px;
    }
  
    .ffolderdiv {
      gap: 8px;
    }
  
    .logo__modal__im {
      width: 50px;
      height: 50px;
    }
  
    .delete__task_text {
      font-size: 14px;
    }
  }
  

  @media (max-width: 330px) {

    .addmodell {
        /* width: 95%; */
        width: 295px;

    }
    .add__task__modal__container_1 {
        padding: 0px;
    }


    .inputEnterfolder {
font-size: 10px !important;

    }

  }