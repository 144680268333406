.modal-backdrop.show {
    backdrop-filter: blur(114px);
    background-color: rgba(0, 0, 0, 0.5);
    background: #24262B;

  }
  
  .deeletemodel{
    width: 535px;
height: 438px;
border-radius: 16px;
backdrop-filter: blur(114px);
    background: #24262B;


  }

  .modal-content {
    background: #24262B !important;
    /* backdrop-filter: blur(114px) !important; */
    width: 100%;
    height: auto;
    gap: 17px;
    border-radius: 16px;
    border: none !important;
  }

.delete__task__modal_container
{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: auto;
    padding: 45px;
    background: #24262B;

    
}
.modal__main__container{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    backdrop-filter: blur(10px);
}
.close__image_container
{
    display: flex;
    /* position: absolute;
    top: 20px;
    right: 19px;  */
    cursor: pointer;
}
.close__image_container-1
{
    border-bottom: none !important;
    justify-content: flex-end !important;
    padding: unset !important
}
.delete__task__modal__container_1
{
    display: flex;
    flex-direction: column;
    gap:40px;
    justify-content: center;
    align-items: center;
}
.delete__task__modal__heading_container
{
    display: flex;
    flex-direction: column;
    gap:25px;
    align-items: center;
}
.logo__modal__image
{
    height: 100px;
    width: 100px;
}
.delete__task_text
{
    font-family: Montserrat-Bold;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;


}

.delete__task_text_P{
    font-family: Montserrat;
font-size: 16px;
font-weight: 400;
line-height: 16px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #D7D8D9;
margin: 0px !important;
}
.delete__modal__button_container
{
    display: flex;
    gap: 20px
}
.delete__modal_cancel_button
{
    width: 190px;
    height: 60px;
    /* padding: 23.5px 63.5px 23.5px 63.5px ; */
    border-radius: 16px;
    background: #393C43;
    backdrop-filter: blur(16px);
    border: none;
    font-family: Montserrat-SemiBold;
font-size: 18px;
font-weight: 600;
line-height: 18px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px !important;
}
.delete__modal_yes_button
{
    width: 190px;
    height: 60px;
    /* padding: 16px 124px 16px 124px; */
    border-radius: 16px;
    background: #0F6FFF;
    backdrop-filter: blur(16px);
    font-family: Montserrat-SemiBold;
font-size: 18px;
font-weight: 600;
line-height: 18px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px !important;
border: none;
    
}
@media (max-width: 480px) {
    .delete__task__modal_container {
        padding: 10px;
    }
    .delete__task__modal_container {
        font-size: 20px;
        line-height: 28px;
    }
    .delete__task__modal_container
    {
       width: 100%;
    }
}








/* ................................. */


@media (max-width: 768px) {
    .deeletemodel {
      width: 100%;
      height: auto;
      border-radius: 12px;
      padding: 20px;
    }
  
    .delete__task__modal_container {
      padding: 20px;
    }
  
    .delete__task__modal__container_1 {
      gap: 20px;
    }
  
    .delete__task__modal__heading_container {
      gap: 15px;
    }
  
    .logo__modal__image {
      height: 80px;
      width: 80px;
    }
  
    .delete__task_text {
      font-size: 20px;
      line-height: 24px;
    }
  
    .delete__task_text_P {
      font-size: 14px;
      line-height: 18px;
    }
  
    .delete__modal__button_container {
      flex-direction: row;
      gap: 10px;
      width: 100%;
    }
  
    .delete__modal_cancel_button,
    .delete__modal_yes_button {
      width: 100%;
      height: 50px;
    }
  }
  
  @media (max-width: 480px) {
    .logo__modal__image {
        height: 65px;
        width: 70px;
    }

    

    .deeletemodel {
      width: 100%;
      padding: 15px;
    }
  
    .delete__task_text {
      font-size: 17px;
    }
  
    .delete__task_text_P {
      font-size: 11px;
    }
  
    .delete__modal_cancel_button,
    .delete__modal_yes_button {
      font-size: 16px;
      height: 45px;
    }
    .delete__task_text {
        font-size: 16px;
    }
    .delete__task__modal__heading_container {
        gap: 11px;
    }

  }
  

  @media (max-width: 380px) {
  .delete__modal_cancel_button, .delete__modal_yes_button {
    font-size: 14px;
    height: 36px;
}
  }



  @media (max-width: 330px) {
    .delete__task_text {
        font-size: 15px;
    }
    .delete__task__modal__heading_container {
        gap: 9px;
    }
    .delete__modal_cancel_button, .delete__modal_yes_button {
        font-size: 12px;
        height: 36px;
    }
    .delete__task__modal__container_1 {
        gap: 15px;
    }

  .delete__task__modal_container {
    padding: 0px;
}

.delete__modal__button_container {
  flex-direction: row;
  gap: 10px;
  width: 100%;
}
  }