.Contentc {
  width: 100%;
  padding: 70px 76px 40px 3px;
  background: #111214;
  gap: 30px;
  display: flex;
  flex-direction: column;
}


.carddivMain {
  display: flex;
  flex-direction: column;
  width: 324px;
  height: 414px;
  padding: 12px 12px 20.88px 12px;
  gap: 0px;
  border-radius: 16px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #0F6FFF;
  box-shadow: 0px 0px 0px 4px #0F6FFF40;

}





.ContentLibrary1 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContentLibrary1_div1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ContentLibrary1_div1_p {
  margin: 0px !important;
}

.ContentLibrary1_div1_p1 {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0f6fff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span1 {
  font-family: Montserrat-SemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span2 {
  font-family: Montserrat-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0px !important;
}

.ContentLibrary1_div1_p1span3 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0px !important;
  color: #9ea0a5;
}

.ContentLibrary1_div2 {
  width: Hug (118px) px;
  height: Hug (40px) px;
  padding: 10px 20px 10px 20px;
  gap: 7px;
  border-radius: 8px;
  background: #393c43;
}

/* ............. */

/* .................... */


.videos {
  position: absolute;
  /* width: 200px;
    height: 200px; */

  width: 300px;
  height: 240px;
  border-radius: 10px;
  background: #0000004D;




}

.thumbnail-image {
  position: absolute;
  /* width: 100%;
    height: 100%; */
  object-fit: cover;
  /* z-index: 1; */
  pointer-events: none;

  width: 300px;
  height: 240px;
  border-radius: 10px;
  background: #0000004D;


}

.itemm {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  border: 1px solid transparent;
  width: 324px;
  /* height: 414px; */
  padding: 12px 12px 20.88px 12px;
  border-radius: 16px;
  background: #24262B;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;

  cursor: pointer;
  border: 1px solid transparent;

}

.itemm:focus-within {
  background: #393C43;
  border: 1px solid #0F6FFF;
  box-shadow: 0px 0px 0px 4px #0F6FFF40;
}

.itemm:hover {
  opacity: 0.7;
}

.deleteiconsss:hover {
  opacity: 0.7;
}


.playB {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #24262b;
  border: 1px solid #0f6fff;
  backdrop-filter: blur(14px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.containerr {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
  background-color: #121212;
}











.image {
  width: 300px;
  height: 240px;
  border-radius: 10px;
  /* width: 100%; */
  /* height: auto; */
  display: flex;
  flex-direction: column;
  gap: 15px;
object-fit: cover;

}

/* ................ */




.videowrapper1 {
  /* background: #0000004D; */
  width: 200px;
  height: 200px;
  border-radius: 16px;

  width: 300px;
  height: 240px;
  border-radius: 10px;
  /* width: 100%; */
  /* height: auto; */
  display: flex;
  flex-direction: column;
  gap: 15px;

  align-items: center;


}

/* ......................... */

.play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}


.playB {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #24262B;
  border: 1px solid #0F6FFF;
  backdrop-filter: blur(14px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.ContentLibraryCards_div2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.ContentLibraryCards_div2_ptag {
  font-family: Montserrat-SemiBold;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: -0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  margin: 0px !important;
}

.ContentLibraryCards_p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
  margin: 0px !important;
}

.ContentLibraryCards_lastdiv {
  display: flex;
  gap: 3px;
}

.ContentLibraryCards_lastdivPtag {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 400;
  line-height: 14.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9EA0A5;
  margin: 0px !important;
}


.dropdownmain11 {
  background: transparent !important;
  border: none !important;
  padding: 0px !important;

}

.deleteiconsss:hover {
  opacity: 0.7;
  color: #FFFFFF !important;

}

.DropDownmenuOptionDivvvvv {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 12px !important;
  border: 1px solid transparent !important;
  /* 
    width: Hug (182px)px;
    height: Hug (40px)px; */
  padding: 8px !important;
  border-radius: 8px !important;
}

.DropDownmenuOptionDivvvvv:hover {
  /* opacity: 0.7 !important; */
  background: #393C43 !important;

  border: 1px solid #0F6FFF !important;

  background: #393C43 !important;

  color: #FFFFFF !important;


}


.DropDownmenuOption {
  font-family: Montserrat-Medium !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17.07px !important;
  text-align: left !important;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
  color: #9EA0A5 !important;
  margin: 0px !important;
}

.DropDownmenuOption:hover {
  color: #FFFFFF !important;
  background: transparent !important;
}







.addcontentbtn {
  width: 170px;
  height: 45px;
  padding: 13px 46px 15px 46px;
  border-radius: 16px;
  background: #0F6FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  white-space: nowrap;
  margin: 0px !important;
  font-family: Montserrat-Bold;
  font-size: 14px;
  font-weight: 700;
  line-height: 15.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  border: none;

}

.plusi {}

.addcontentbtndiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}



@media (max-width: 1070px) {
  .addcontentbtndiv {
    /* flex-direction: row-reverse; */
  }

}





@media (max-width: 850px) {
  .Contentc {
    width: 100%;
    padding: 70px 45px 40px 45px;

  }

}

@media (max-width: 500px) {
  .addcontentbtn {
    padding: 15px 30px 15px 30px;
  }

  .ContentLibrary1_div1 {
    flex-wrap: wrap;
  }

  .videowrapper1 {

    width: 100%;
  }

}

@media (max-width: 769px) {
  .itemm {
    width: 100%;
  }

  .videos,
  .videowrapper1 {
    width: 100%;
  }

  .Contentc {
    width: 100%;
    padding: 70px 0px 0px 0px;
  }

  .image {
    width: 100%;
  }

  .containerr {
    align-items: center;
    justify-content: center;
  }
}




@media (max-width: 520px) {
  .thumbnail-image {
    width: 85%;
    height: 55%;
  }

  .videos {
    width: 87%;
  }

  .Contentc {
    padding: 70px 0px 0px 0px;
  }


  .ContentLibrary1_div1 {
    gap: 20px;
  }

  .dddrop {
    width: 100%;
  }

  .image {
    width: 100%;
  }

  .thumbnail-image {
    width: 90%;
    height: auto;
  }

}


@media (max-width: 410px) {
  .Contentc {
    padding: 70px 0px 0px 0px;
  }

  .videowrapper1 {
    /* background: #0000004d; */
    width: 100%;
  }
}


@media (max-width: 330px) {
  .itemm {
    padding: 12px 11px 20.88px 11px;
  }

  .Contentc {
    width: 100%;
    padding: 70px 0px 0px 0px;
  }

  .videos {
    width: 92%;
  }
}