.coursesmain {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
    border-radius: 8px !important;
    padding: 70px 30px 150px 7px !important;

}

.InstagramCourses_tableMain {
    /* margin-top: 50px !important; */
    background-color: #030303 !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    box-shadow: none !important;
}

.InstagramCourses_tableMain::-webkit-scrollbar {
    display: none !important;
}

.InstagramCourses_table {
    width: 1, 052px;
    height: 45px !important;
    border-radius: 8px !important;

    border-bottom: 1px solid #24262B !important;
}

.InstagramCourses_tableMain_head {
    height: 45px !important;
    border-radius: 8px !important;
    background: #24262B !important;

    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
}


.InstagramCourses_tableMain_cell1 {
    font-family: Montserrat-Medium !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    border-bottom: 1px solid #24262B !important;
    background: #24262B !important;
    margin: 0px !important;
    color: #9EA0A5 !important;

}

.InstagramCoursesTable_row {
    background: #24262B !important;
    border-top-left-radius: 8px !important;

}

.emptytexttt {
    font-family: Montserrat !important;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
}

.empyttytopmain {
    width: 100%;
    margin-top: 80px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 25px;
    background: #24262B;
    border-radius: 16px;
    gap: 25px;

}

.InstagramCourses_tableMain_cell1pic {
    font-family: Montserrat-Medium !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    border-bottom: 1px solid #24262B !important;
    background: #24262B !important;
    margin: 0px !important;
    color: #9EA0A5 !important;


}



.InstagramCoursesTable_row_cell {
    /* width: 188px;
height: 60px;
padding: 11px 88px 11px 20px; */
    background: #24262B !important;
    border-bottom: 1px solid #393C43 !important;
    margin: 0px !important;
    font-family: Montserrat-SemiBold !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    color: #FFFFFF !important;
}

.InstagramCoursesTable_row_cell1 {
    background: #24262B !important;
    border-bottom: 1px solid #393C43 !important;
    margin: 0px !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20.3px !important;
    text-align: left !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
    color: #FFFFFF !important;

}

.InstagramCoursesTable_row_cell2 {

    background: #24262B !important;
    margin: 0px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 15px !important;


}

.instadiv {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
}

.instadivptag {
    font-family: Montserrat-SemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    margin: 0px !important;
}

.insta {
    width: 145px;
    height: 45px;
    /* padding: 13px 16px 15px 15px; */
    border-radius: 16px;
    background: #0F6FFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border: none;
    margin: 0px !important;
    font-family: Montserrat-Bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;


    cursor: pointer;


}


.insta:hover {
    opacity: 0.7 !important;
}



@media(max-width:769px) {
    .coursesmain {
        padding: 70px 10px 80px 10px !important;
    }
    .emptytexttt {
        font-family: Montserrat !important;
        font-size: 24px;
        font-weight: 700;
        line-height: 24.8px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        margin: 0px !important;
    }
}

@media(max-width:500px) {
    .coursesmain {
        padding: 70px 0px 80px 0px !important;
    }
    .emptytexttt {
        font-family: Montserrat !important;
        font-size: 20px;
        font-weight: 700;
        line-height: 20.8px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        margin: 0px !important;
    }
}




@media(max-width:450px) {
    .instadivptag {
        font-family: Montserrat;
        font-size: 16px;
    }

    .emptytexttt {
        font-family: Montserrat !important;
        font-size: 19px;
        font-weight: 700;
        line-height: 20.8px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #FFFFFF;
        margin: 0px !important;
    }
    .insta {
        width: 115px;
        height: 35px;
        padding: 5px;
        font-size: 11px;
        gap: 5px;
    }

}

@media(max-width:450px) {

    .coursesmain {
        padding: 70px 0px 80px 0px !important;
    }
}



@media(max-width:350px) {

    .instadivptag {
        font-family: Montserrat;
        font-size: 14px;
    }

    .insta {
        width: 98px;
        height: 33px;
        padding: 4px;
        font-size: 9px;
        gap: 3px;
    }




    .coursesmain {
        padding: 70px 5px !important;
    }

}





.InstagramCoursesTable_row_cell img {
    cursor: pointer;
}