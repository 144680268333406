
.info__main_containerr {
    max-width: 864px;
    padding: 45px 16px 208px 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .MuiDropzoneSnackbar-successAlert {
    display: none !important;
  }

.maindropzzz
{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  height: 155px;
  padding: 35px 25px;
  
}


.InfoContentLibraryMain1{
    display: flex;
    flex-direction: column;
    gap: 30px ;
}
.InfoContentLibraryMain1_p{
    font-family: Montserrat-Bold;
font-size: 20px;
font-weight: 700;
line-height: 24.38px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
margin: 0px !important;
}
.InfoContentLibraryMain1_div{
    padding: 32px ;
    display: flex;
    flex-direction: column;
    gap: 40px ;
    border: 1px solid #24262B;
    border-radius: 16px ;
    margin-bottom: 32px !important;
}
.publish__button__main__containerr {
    display: flex;
    flex-direction: row;
    gap: 20px;

  }

  .publish___buttons_main {
    height: 60px;
    width: 190px;
    border-radius: 16px;
    background: #0f6fff;
    border: none;
    font-family: Montserrat-Semibold;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
  .cancel___buttons_main {
    height: 60px;
    width: 190px;
    border-radius: 16px;
    background: #393c43;
    backdrop-filter: blur(16px);
    border: none;
    font-family: Montserrat-Semibold;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }


  .infocontact__inputlabel {
    font-family: Montserrat-Bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: left;
    color: #ffffff;
    margin: 0px !important;
  }


  .info_content__input_placeholder_description {
    padding: 20px 18px 113px;
    background: #24262b;
    border-radius: 16px;
    border: none;
    color: #9ea0a5
  }
  .info_content__input_placeholder_description::placeholder {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: #9ea0a5;
    width: 100%;
  }
  .info_content__input_placeholder_description:focus {
    outline: none;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #9ea0a5 !important;
  }



  .infocontent__input_placeholder {
    padding: 20px 18px;
    background: #24262b;
    border-radius: 16px;
    border: none;
    color: #9ea0a5
  }
  .infocontent__input_placeholder::placeholder {
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: #9ea0a5;
    width: 100%;
  }
  .infocontent__input_placeholder:focus {
    outline: none;
    font-family: Montserrat-Medium;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #9ea0a5;
  }







@media(max-width:520px){

  .InfoContentLibraryMain1_div {

    padding: 20px;

  }
}



@media(max-width:430px){
    .InfoContentLibraryMain1_p {
        font-size: 16px;

    }
    .infocontent__input_placeholder::placeholder {
        font-size: 14px;  
    }
    .infocontact__inputlabel {

        font-size: 14px;

    }


.info__main_containerr {
    padding: 45px 3px 208px 3px;
}

.publish___buttons_main {
    width: 160px ;
    height: 50px;
}
.cancel___buttons_main{
    /* width: 50% ; */
    width: 160px ;
    height: 50px;
}
.publish__button__main__containerr{
    gap: 10px;
}
.publish___buttons_main{
    font-size: 16px;
}
.cancel___buttons_main{
    font-size: 16px;
}
}