body {
  background-color: #101214 !important;
}


.lottttty
{
  height: 500px !important;
  width: 500px !important;
}
@media(max-width:769px)
{
  .lottttty
{
  height: 300px !important;
  width: 300px !important;
}

}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.loader div {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  animation: wave 1.2s infinite ease-in-out;
}

.loader div:nth-child(2) {
  animation-delay: -1.1s;
}

.loader div:nth-child(3) {
  animation-delay: -1s;
}

@keyframes wave {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/FontFamily/Montserrat-Regular.ttf") format("truetype");
}

/* 500 */
@font-face {
  font-family: "Montserrat-Medium";
  src: url("/public/FontFamily/Montserrat-Medium.ttf") format("truetype");
}

/* 600 */
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("/public/FontFamily/Montserrat-SemiBold.ttf") format("truetype");
}

/* 700 */
@font-face {
  font-family: "Montserrat-Bold";
  src: url("/public/FontFamily/Montserrat-Bold.ttf") format("truetype");
}

* {
  margin: 0px !important;
}